:root {
  background-color: #f5f5f5;
  color: black;
}



@font-face {
  font-family: "Metropolis";
  src: url("font/Metropolis-Regular.otf");
}

@font-face {
  font-family: "Metropolis-Bold";
  src: url("font/Metropolis-Bold.otf");
}

@font-face {
  font-family: "Metropolis-Light";
  src:url("font/Metropolis-Light.otf");
}

.text-head {
  font-size: calc(14px + 2vmin);
  font-family: "Unbounded";
  font-weight: 900;
  animation: swipeUp 500ms ease-in-out;
}

.name {
  color: #cf392e;
  animation: spawnShadow 500ms ease 1s forwards;
  text-decoration: none;
}

.name-pages {
  color: #cf392e;
  animation: spawnShadow 500ms ease 1s forwards;
  text-decoration: none;
  font-size: 5em
}

h1 {
  position: relative;
}

h1::before,
h1::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
}

h1::before {
  animation: typewriter 0.75s steps(15) forwards;
  background-color: #f5f5f5;
}

@keyframes typewriter {
  to {
    left: 100%;
  }
}

.description {
  font-size: calc(8px + 2vmin);
  animation: swipeLeft 500ms ease-in-out;
}

.description-head {
  font-size: calc(14px + 2vmin);
  animation: swipeLeft 500ms ease-in-out;

}

.text-block {
  padding: 0 5em 2vh 5em;
  text-align: left;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  animation: fadeIn 1000ms;
}

.Project {
  background-color: #07A5C3;
  padding: 1em;
  margin-bottom: -5vh;
  color: white;
  font-weight: bold;
}

.Nav {
  background-color: #f5f5f5;
  min-height: 15vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  opacity: 1;
  position: sticky;
  top: 0;
  z-index: 15;
}

.Personal-info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.Side-image {
  transition: all 500ms ease;
  max-height: 30vh;
  object-fit: cover;
  border: 4px solid #cf392e;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-left: 5em;
  border-radius: 1em;

  &:hover {
    transition: all 500ms ease;
    filter: drop-shadow(0.25em 0.70vh black);
  }
}

.Projects {
  background-color: #07A5C3;
  display: flex;
    flex-direction: column;
    position: relative;
    margin: 40px 0;
  width: auto;
}

.project-holder {
  display: flex;
  flex-direction: row;
  flex: 0 0 33%;
  flex-wrap: wrap;
  justify-content: center;
}

// .Projects::after {
//       background-color: #CFBF2D;
//       content: '';
//       position: absolute;
//       left: calc(50% - 2px);
//       width: 4px;
//       height: 100%;
//   }

  .timeline-item {
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    position: relative;
    margin: 10px 0;
    width: 50%;
}

.timeline-item:nth-child(odd) {
    align-self: flex-end;
    justify-content: flex-start;
    padding-left: 30px;
    padding-right: 0;
}

.timeline-item-content {
  animation: swipeLeft 500ms forwards;
  animation-delay: 100ms;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px;
  position: relative;
  width: 400px;
  max-width: 70%;
  text-align: right;

  img {
    width: 10em;
    height: 10em;
  }
}

.timeline-item-content::after {
  content: ' ';
  background-color: #fff;
  box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: -7.5px;
  top: calc(50% - 7.5px);
  transform: rotate(45deg);
  width: 15px;
  height: 15px;
}

.timeline-item:nth-child(odd) .timeline-item-content {
  text-align: left;
  align-items: flex-start;
}

.timeline-item:nth-child(odd) .timeline-item-content::after {
  right: auto;
  left: -7.5px;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.timeline-item-content .tag {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  top: 5px;
  left: 5px;
  letter-spacing: 1px;
  padding: 5px;
  position: absolute;
  text-transform: uppercase;
}

.timeline-item:nth-child(odd) .timeline-item-content .tag {
  left: auto;
  right: 5px;
}

.timeline-item-content time {
  color: #777;
  font-size: 12px;
  font-weight: bold;
}

.timeline-item-content p {
  font-size: 16px;
  line-height: 24px;
  margin: 15px 0;
  max-width: 250px;
}

.timeline-item-content a {
  font-size: 14px;
  font-weight: bold;
}

.timeline-item-content a::after {
  content: ' ►';
  font-size: 12px;
}

.timeline-item-content .circle {
  background-color: #fff;
  border: 3px solid #CFBF2D;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 10px);
  right: -40px;
  width: 20px;
  height: 20px;
}

.timeline-item:nth-child(odd) .timeline-item-content .circle {
  right: auto;
  left: -40px;
}

@media only screen and (max-width: 1023px) {
  .timeline-item-content {
      max-width: 100%;
      img {
        width: 5em;
        height: 5em;
      }
  }

  .text-body {
    display: flex;
    flex-direction: column;
  }

  .skills-section {
    flex-direction: row;
  }

}

@media only screen and (max-width: 767px) {
  .timeline-item-content,
  .timeline-item:nth-child(odd) .timeline-item-content {
      padding: 15px 10px;
      text-align: center;
      align-items: center;
  }

  .hide-gap {
    margin-top: -7vh;
  }

  .timeline-item-content .tag {
      width: calc(100% - 10px);
      text-align: center;
      margin: -0.5em -.25em 0 -.25em;
  }

  .timeline-item-content time {
      margin-top: 25px;
  }

  .timeline-item-content a {
      text-decoration: underline;
  }

  .timeline-item-content a::after {
      display: none;
  }

  .skills-section {
    flex-direction: column;
    align-items: center;
  }
}

.skills-section,
.skills-section-acco {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 5vh;
}

.skills-section-acco {
  flex-direction: column;
}

.skill-container {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: center;
}

.skill {
  text-align: center;
}

.fai {
  display: flex;
  align-self: center;
  width: 2.5em;
  height: 2.5em;
}

.container-row {
  display: flex;
  text-align: center;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  flex-wrap: nowrap;
}

.container-col {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: space-evenly;
  flex-wrap: nowrap;
}

#resume {
  width: 25em;
  justify-content: center;
}

.animateda {
  animation: swipeUp 500ms;
  animation-fill-mode: forwards;
}

.Button {
  color: black;
  opacity: 0;
  transition: all 100ms ease-out;
  border: 3px solid transparent;
  font-size: calc(8px + 2vmin);
  font-family: 'Unbounded';
  font-weight: 900;
  padding: 1em;
  margin: 1em;
  border-radius: 1em;
  background-color: #f5f5f5;
  animation: swipeUp 500ms;
  animation-fill-mode: forwards;
  text-decoration: none;

  &:hover {
    transition: all 100ms ease-out;
    background: linear-gradient(145deg, #ffffff, #d8d8d8);
    border: 3px solid #cf392e;
    filter: drop-shadow(0.1em 0.75vh black);
  }
}

.connect {
  z-index: 1;
  right: 0;
  position: fixed;
  top: 50%;
  transform: translate(0px,-50%);
  display: flex;
  flex-direction: column;
  max-width: 2em;
  padding: 1vmin;
  border: none;
  border-radius: 1em;
  background: linear-gradient(145deg, #ffffff, #d8d8d8);
  border: 3px solid #cf392e;
    filter: drop-shadow(0.1em 0.75vh black);
}

.connect-image {
  max-width: 2em;
  max-height: 2em;
}

.notif {
  display: flex;
  justify-content: center;
  position: fixed;
  left: 47%;
  right: 47%;
  top: 40%;
  font-family: "Unbounded";
  text-align: center;
  z-index: 3;
  min-width: 5em;
  background: rgba(255, 255, 255, 0);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.9px);
  -webkit-backdrop-filter: blur(3.9px);
  border: 1px solid rgba(255, 255, 255, 0.16);
  padding: 1em;  
}


.shadow-on-hover {
  transition: all 500ms ease;
  &:hover {
    transition: all 500ms ease;
    filter: drop-shadow(0.35em 0.5vh black);
  }
}

@media only screen and (max-width: 600px) {

  .text-block {
    padding: 0 1.25em 0 1.25em;
    text-align: left;
  }

  .projects-section {
    flex: 1 1 50%;
    margin: 0 5em 0 5em;
  }
  .name-pages {
    color: #cf392e;
    animation: spawnShadow 500ms ease 1s forwards;
    text-decoration: none;
    font-size: 2em
  }
  
  .container-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .Nav button{
    justify-content: space-evenly;
    size: 505%;
  }

  .Personal-info {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .project-image {
    width: 16px;
    height: 16px;
  }

  .Side-image {
    transition: all 500ms ease;
    max-height: 30vh;
    object-fit: cover;
    border: 4px solid #cf392e;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    margin-right: 5em;
    border-radius: 1em;

    &:hover {
      transition: all 500ms ease;
      filter: drop-shadow(0.1em 0.35vh black);
    }
  }

  .skill-container {
    width: 33%;
  }

  .Side-image-proj {
    max-height: 30vh;
    object-fit: cover;
    margin-right: 5em;
    border-radius: 1em;
  }

  .Side-image-proj-2 {
    max-height: 30vh;
    object-fit: cover;
    margin-left: 5em;
    margin-right: 5em;
    border-radius: 1em;
  }

  .App-logo {
    height: 8vmin;
    pointer-events: none;
    animation: sway 1250ms infinite ease-in-out;
  }

  .App-logo-still {
    height: 8vmin;
    padding-top: 2vmin;
    pointer-events: none;
  }

  .connect {
    top: 20%;
    z-index: 20;
  }

  .notif {
    left:35%;
  }



  @keyframes spawnShadow {
    0% {
    }
  
    100% {
      filter: drop-shadow(0.1em 0.35vh black);
    }
    
  }
  
}

a {
  text-decoration: none;
  color: black;
}

.connect img {
  padding-top: 0.33vh;
  padding-bottom: 0.33vh;
}

.connect img:hover {
  animation: flash 500ms;
}

p {
  font-family: 'Unbounded';
  color: black;
}

.Nav button:nth-child(1) { animation-delay: 0s;}
.Nav button:nth-child(2) { animation-delay: 0.25s;}
.Nav button:nth-child(3) { animation-delay: 0.5s;}

.fade-appear {
  opacity: 0;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: all 500ms linear;
}

.fade-exit {
  opacity: 0;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms linear;
}

.fade-exit-done {
  opacity: 0;
}

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 300ms;
}

@keyframes fadeIn{
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes swipeLeft {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes swipeUp {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes sway {
  0%,100% {
    transform: translateY(-0.5vh);
  }
  50% {
    transform: translateY(0.5vh);
  }
}

@keyframes flash {
  0%, 50%, 100% {
     opacity: 1;
  }
  25%, 75% {
     opacity: 0;
  }
}


@media screen and (min-width: 600px) {
  @keyframes spawnShadow {
  0% {
  }

  100% {
    filter: drop-shadow(0.1em 0.72vh black);
  }
  
}}
