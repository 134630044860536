.project-container {
    opacity: 0;
    background-color: white;
    width: 35rem;
    height: 50vh;
    margin: 1rem;
    border-radius: 10px;
    border: 4px solid #cf392e;
    color: #cf392e;
    animation-delay: calc(300ms * var(--order));
}

.tag {
    color: white;
    padding-left: .25rem;
    padding-right: .25rem;
    margin-bottom: 5vh;
}

.proj-logo {
    height: 10rem;
}

.date {
    font-weight: 900;
    margin-top: 0vh;
}

.desc {
    padding-left: 1rem;
    padding-right: 1rem;
}

@media only screen and (max-width: 767px) {
    .project-container {
        height: fit-content;
    }
}