.contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.input-field, .input-textarea {
    transition: all 500ms ease;
    margin: 0vh 10vh 5vh 10vh;
    padding: 1em;
    font-size: larger;
    font-family: 'Unbounded';
    margin-trim: bottom;
    border: 4px solid #cf392e;
    border-radius: 15px;
    resize: vertical;

    &:hover, &:focus {
        transition: all 500ms ease;
        filter: drop-shadow(0.25em 0.70vh black);
      }
}

.contact {
    margin: 0 10vh 0vh 10vh;
    color: black;
    border: 4px solid transparent;
    border-radius: 15px;
    width: 50em;
    padding-bottom: 1vh;
    margin-bottom: 5vh;
}

.contact-desc {
    color: black;
}

.contact-btn {
    transition: all 500ms ease;
    width: 50%;
    background-color: #07A5C3;
    padding: 1vh 0 1vh 0;
    border: 4px solid transparent;
    border-radius: 15px;
    font-family: "Unbounded";
    font-size: large;
    color: white;

    &:hover {
        transition: all 500ms ease;
        filter: drop-shadow(0.25em 0.70vh black);
      }
}

